import { useToast } from 'vue-toastification'
import ToasterBody from '@/components/toaster/ToasterBody.vue'
import { ADD_IN_VERSION_KEY, APP_VERSION_KEY } from '../data/localStorageKeys'
import { ToastContent, ToastID, ToastOptions } from 'vue-toastification/src/types'
import { TYPE } from 'vue-toastification/src/ts/constants'
import { AxiosResponse } from 'axios'
import { IMessage } from '@stomp/stompjs'
import { StoredVersion } from '@/types/version'

const toast = useToast()

export function successToast(
  { title, message }: ToastContent,
  options?: ToastOptions & { type?: TYPE.SUCCESS }
): ToastID {
  return toast.success(
    {
      component: ToasterBody,
      props: {
        title,
        message
      }
    },
    options
  )
}

export function errorToast(
  { title, message }: ToastContent,
  options?: ToastOptions & { type?: TYPE.ERROR }
): ToastID {
  return toast.error(
    {
      component: ToasterBody,
      props: {
        title,
        message
      }
    },
    options
  )
}

export const checkVersion = (response?: AxiosResponse | IMessage): void => {
  const apiVersion = new StoredVersion(APP_VERSION_KEY, response?.headers['current-version'])
  const addInVersion = new StoredVersion(ADD_IN_VERSION_KEY, response?.headers['add-in-version'])
  apiVersion.persistCurrent()
  addInVersion.persistCurrent()
  if (apiVersion.hasChanged() || addInVersion.hasChanged()) {
    // Reload the app to get the latest version
    window.location.reload()
  }
}
