export default {
  common: {
    copied: 'Copié !',
    copy: 'Copier',
    errorOccurred: 'Une erreur est survenue',
    library: 'Bibliothèque',
    send: 'Envoyer'
  },
  AssistantSourceModal: {
    caselawDate: 'Rendue le {date}',
    doctrinalDate: 'Publiée le {date}',
    lawDate: 'En vigueur le {date}',
    sharedDocumentDate: 'Modifié le {date}',
    title: 'Source citée | Sources citées',
    lawSourceTitle: '{article} - {title}'
  },
  ChatInputBar: {
    cancelReply: 'Stopper la réponse',
    placeholder: 'Poser une question ou sélectionner un passage de votre document'
  },
  ChatMessageSources: {
    title: 'Source citée | Sources citées'
  },
  ChatView: {
    newChat: 'Nouvelle conversation',
    welcomeMessageConnected: 'Bonjour {name}, comment puis-je vous aider aujourd’hui ?'
  },
  connected: {
    failedUserInfo:
      'Impossible de récupérer les informations utilisateur. Veuillez réessayer ou vous reconnecter.'
  },
  ConnectedLayout: {
    assistant: 'Assistant',
    tools: 'Outils',
    feedbackGiven: 'Erreur signalée'
  },
  LandingView: {
    connect: 'Se connecter',
    contactUs: 'Contactez-nous',
    needHelp: 'Besoin d\'aide ?',
    noAccount: 'Vous n\'avez pas de compte ?',
    register: 'Inscription',
    title: 'Recherchez des documents, enrichissez vos textes et obtenez la réponse à toutes vos questions juridiques'
  },
  ReplaceTool: {
    searchPlaceholder: 'Chercher',
    replacePlaceholder: 'Remplacer',
    replaceAll: 'Tout remplacer'
  },
  RobotChatMessage: {
    assistant: 'Assistant',
    dislike: 'Je n’aime pas',
    feedbackMessage: 'Merci pour votre retour !',
    insertText: 'Insérer le texte',
    like: 'J’aime',
    newReply: 'Nouvelle réponse',
    seeSources: 'Voir les sources',
    sourceText:
      'Source : conversation avec Assistant du {date} | Sources : conversation avec Assistant du {date}'
  },
  Tools: {
    inputPlaceholder: 'Sélectionnez un passage de votre document',
    insertText: 'Insérer le texte'
  },
  ToolsView: {
    improve: 'Améliorer',
    replace: 'Remplacer',
    translate: 'Traduire'
  },
  TranslateTool: {
    autoDetect: 'Détection automatique',
    detectedLanguage: '{lang} (détecté)',
    errorMessage:
      'Une erreur est survenue lors de la traduction. Veuillez réessayer ou contacter le support.',
    languages: {
      AR: 'Arabe',
      BG: 'Bulgare',
      CS: 'Tchèque',
      DA: 'Danois',
      DE: 'Allemand',
      EL: 'Grec',
      EN: 'Anglais',
      'EN-US': 'Anglais (américain)',
      'EN-GB': 'Anglais (britannique)',
      ES: 'Espagnol',
      ET: 'Estonien',
      FI: 'Finlandais',
      FR: 'Français',
      HU: 'Hongrois',
      ID: 'Indonésien',
      IT: 'Italien',
      JA: 'Japonais',
      KO: 'Coréen',
      LT: 'Lituanien',
      NB: 'Norvégien (bokmål)',
      NL: 'Néerlandais',
      LV: 'Letton',
      PL: 'Polonais',
      PT: 'Portugais',
      'PT-PT': 'Portugais',
      'PT-BR': 'Portugais (brésilien)',
      RO: 'Roumain',
      RU: 'Russe',
      SK: 'Slovaque',
      SL: 'Slovène',
      SV: 'Suédois',
      TR: 'Turc',
      UK: 'Ukrainien',
      ZH: 'Chinois simplifié'
    }
  },
  UserChatMessage: {
    user: 'Vous'
  },
  UserPicture: {
    imgAlt: "Photo d'utilisateur"
  },
  Selection: {
    selectionLimitTitle: 'Limite de caractères atteinte.',
    selectionLimitMessage: 'Veuillez sélectionner un passage plus court.'
  }
}
