export class StoredVersion {
  localStorageKey: string
  stored: string | null | undefined
  current: string | null | undefined

  constructor(localStorageKey: string, currentVersion: string | null | undefined) {
    this.localStorageKey = localStorageKey
    this.stored = localStorage.getItem(localStorageKey)
    this.current = currentVersion
  }

  hasChanged(): boolean {
    return !!this.stored && !!this.current && this.stored !== this.current
  }

  persistCurrent(): void {
    if (this.current) {
      localStorage.setItem(this.localStorageKey, this.current)
    }
  }
}
